@import '~@ebay/skin/tokens.css';
.parts-scheduling__image {
  width: 100%;
  border-radius: 5px;
}
.parts-scheduling__service-operation {
  margin-bottom: 16px;
}
.parts-scheduling__service-operation p {
  padding-left: 8px;
  margin: 0;
  font-size: var(--font-size-small);
  line-height: 16px;
}
.parts-scheduling__service-operation svg {
  color: var(--color-foreground-secondary);
}
.parts-scheduling__clock span,
.parts-scheduling__provider-text span {
  display: flex;
  justify-content: space-between;
  padding: 1px;
}
.parts-scheduling__phone span,
.parts-scheduling__place span {
  color: var(--color-foreground-primary);
}
.parts-scheduling__help {
  padding-bottom: 24px;
}
.parts-scheduling__help h4 {
  font-size: var(--font-size-default);
  line-height: 20px;
  font-weight: var(--font-weight-bold);
}
.parts-scheduling__help-item ul {
  padding-left: 32px;
  margin: 0;
}
.parts-scheduling__help-item li {
  font-size: var(--font-size-default);
  line-height: 20px;
  color: var(--color-foreground-secondary);
}
.parts-scheduling__provider {
  margin-top: 24px;
  padding: 16px;
  background-color: var(--color-background-secondary);
  border-radius: 5px;
}
.parts-scheduling__provider-image {
  object-fit: cover;
}
.parts-scheduling__provider-text {
  padding: 0 0 0 16px;
}
.parts-scheduling__provider-text span {
  display: inline-block;
  padding: 0 0 5px 8px;
  color: var(--color-foreground-primary);
}
.parts-scheduling__provider-text svg {
  padding-left: 5px;
}
.parts-scheduling_faq-title {
  margin: 24px 0;
}
.parts-scheduling__faq-deatils {
  list-style: none;
  padding: 0;
}
.parts-scheduling__installer-card {
  padding-bottom: 16px;
}
.parts-scheduling__installer-card .installer-card-no-radio__name,
.parts-scheduling__installer-card .installer-card-no-radio__address,
.parts-scheduling__installer-card .installer-card-no-radio__star-rating,
.parts-scheduling__installer-card .installer-card-no-radio__accessory-label {
  padding: 0 0 4px 0;
}
.parts-scheduling__installer-card > .installer-card-no-radio {
  width: 100%;
  display: block;
}
.parts-scheduling .installer-card-no-radio__name {
  line-height: 24px;
}
.parts-scheduling .installer-card-no-radio__address {
  line-height: 20px;
}
.parts-scheduling .installer-card-no-radio__star-rating {
  align-items: normal;
  line-height: 16px;
}
.parts-scheduling .installer-card-no-radio__star-rating div.star-rating {
  width: 85px;
}
.parts-scheduling .installer-card-no-radio__star-rating-average {
  margin-left: 0;
}
.parts-scheduling__footer {
  height: 80px;
}
.parts-scheduling__time-slot > .toggle-button-group > ul {
  display: inline-block;
}
.parts-scheduling__time-slot > .toggle-button-group > ul li {
  margin: 0;
  width: auto;
}
.parts-scheduling__time-slot > .toggle-button-group > ul li button:disabled,
.parts-scheduling__time-slot > .toggle-button-group > ul li button[disabled] {
  display: none;
}
.parts-scheduling__time-slot > .toggle-button-group > ul li button {
  width: 92%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 12px;
  height: auto;
}
.parts-scheduling__right-title {
  margin: 0 0 16px;
}
.parts-scheduling__clock > span:first-child {
  padding-bottom: 8px;
}
.parts-scheduling__clock > span > span:first-child {
  padding-right: 16px;
}
.parts-scheduling__map-operation {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-background-tertiary);
}
.parts-scheduling__content,
.parts-scheduling__provider,
.parts-scheduling__map-operation,
.parts-scheduling__service-operation {
  display: flex;
}
.parts-scheduling__content {
  justify-content: space-between;
  padding-bottom: 80px;
}
.parts-scheduling__left,
.parts-scheduling__right {
  width: 50%;
}
.parts-scheduling__left {
  padding: 0 16px 32px 32px;
}
.parts-scheduling__right {
  padding: 0 32px 32px 16px;
}
.parts-scheduling__right .parts-scheduling__faq {
  margin-top: 24px;
}
.parts-scheduling__right .parts-scheduling__faq .details__summary {
  color: var(--color-foreground-primary);
  font-weight: bold;
  padding-left: 0;
  font-size: 14px;
  line-height: 20px;
}
.parts-scheduling__image {
  height: 132px;
  margin-bottom: 24px;
}
.parts-scheduling__map-lists {
  height: 188px;
  width: 188px;
  margin-right: 16px;
}
.parts-scheduling__phone {
  padding: 0 8px 0;
}
.parts-scheduling__phone button {
  text-align: left;
}
.parts-scheduling__calendar {
  padding-top: 16px;
}
.parts-scheduling__help {
  border-bottom: 1px solid var(--color-background-tertiary);
}
.parts-scheduling__installer-card {
  border-bottom: 1px solid var(--color-background-tertiary);
}
.parts-scheduling__installer-card .installer-card-no-radio__feature {
  display: inline-block;
  font-size: var(--font-size-small);
  line-height: 16px;
}
.parts-scheduling__installer-card .installer-card-no-radio__feature span.ux-textspans--SECONDARY {
  display: inline-block;
  white-space: pre-line;
  width: 220px;
}
.parts-scheduling__footer {
  box-shadow: 0 -4px 4px #0000000f;
}
.parts-scheduling__footer.sticky_footer {
  border-radius: 0 0 10px 10px;
}
.parts-scheduling .screen-loading {
  padding: 0 32px 32px;
  height: 750px;
}
.parts-scheduling .screen-loading__content-top .skeleton__textbox {
  height: 192px;
}
.parts-scheduling .screen-loading__content-main {
  padding-top: 32px;
}
.parts-scheduling .screen-loading__content-right .skeleton__image {
  height: 150px;
  margin-bottom: 20px;
}
