@import '~@ebay/skin/tokens.css';
.ctas button.fake-btn--primary:hover,
.ctas a.fake-btn--primary:hover {
  color: var(--color-background-primary);
}
.ctas button:last-child,
.ctas a:last-child {
  margin: 0;
}
.ctas__ok {
  text-align: center;
  background-color: var(--color-background-primary);
  color: var(--color-foreground-secondary);
  border-bottom: 1px solid var(--color-background-disabled);
  height: 0px;
}
.ctas__ok span {
  position: relative;
  top: -8px;
  background-color: var(--color-background-primary);
  padding: 5px;
}
.ctas button:focus {
  outline: 1px solid var(--color-blue-4);
  outline-offset: 1px;
}
.ctas {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 16px 16px 0;
}
.ctas button,
.ctas a {
  margin: 0 0 0 16px;
}
