@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-warranty-interstitial-nudge-d {
  margin: @spacing-300;
  font-size: 0.875rem;

  &.vault-adoption-interstitial-d {
    margin: 0;
  }

  a,
  a:focus,
  a:visited {
    color: var(--color-blue-4);
    text-decoration: underline;
  }

  .vas-interstitial-error {
    min-height: 6rem;

    &-notice {
      p {
        color: #111820;
        font-weight: 500 !important;
      }
    }
  }

  .vas-interstitial-header {
    display: flex;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    justify-content: space-between;

    &.vault-adoption-interstitial-header {
      margin: 1rem;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      border: none;
      background-color: var(--color-background-secondary);
      border-radius: 50%;

      svg.icon.icon--close-16 {
        fill: #111820;
      }
    }
  }

  .vault-adoption-interstitial-body {
    padding-left: @spacing-200;
    padding-right: @spacing-200;
  }

  .vas-interstitial-body__title {
    font-family: var(--family-primary, "Market Sans");
    font-size: var(--size-large2, 24px);
    font-style: normal;
    font-weight: 700;
    line-height: var(--lineHeight-large2, 32px);
    letter-spacing: var(--letterSpacing-none, 0px);
  }

  .vas-cards-section {
    margin-top: @spacing-300;
    padding-left: @spacing-200;
    padding-right: @spacing-200;
  }

  .vas-interstitial-items {
    margin-bottom: @spacing-200;

    .vas-interstitial-item {
      display: flex;
      justify-content: space-between;

      &-left {
        width: 85%;
      }

      &-label {
        line-height: 24px;
        color: #000000;
        font-size: @spacing-200;
        margin-bottom: @spacing-50;
      }
      &-secondaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: @spacing-50;
      }
      &-tertiaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: @spacing-100;
      }
      &-quaternaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: @spacing-100;
      }
      .link-label {
        margin-top: 0.75rem;
      }
    }
  }

  .vas-interstitial-header-img {
    width: 90px;
    height: 18px;
  }

  .vas-interstitial-vault-cta {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    bottom: @spacing-300;
    right: @spacing-300;
    padding-top: @spacing-200;

    &.vault-adoption-interstitial-cta {
      padding: @spacing-200;
    }

    #vault-icon {
      margin-right: @spacing-100;
      padding-bottom: 2px;
    }

    &-btn {
      margin-left: @spacing-200;
      padding: 0px @spacing-200 0px @spacing-200;
    }
  }

  .vas-body-heading {
    margin-top: @spacing-50;
    margin-bottom: @spacing-50;
  }

  .vas-heading {
    margin-top: @spacing-200;
    margin-bottom: @spacing-200;
    display: flex;
    align-items: center;
  }

  .icon-vault {
    display: inline-block;
    margin: 0;
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .heading-text {
    margin-left: @spacing-50;
  }

  .vas-divider {
    border-top: 1px solid var(--color-background-tertiary);
    border-bottom: 1px solid var(--color-background-tertiary);
  }

  .vas-secondary-labels {
    margin-top: @spacing-200;
    margin-bottom: @spacing-200;
  }

  .field {
    display: flex;
    align-items: center;
    .field__label {
      margin-left: @spacing-200;
    }
  }

  .vas-spoke-item-card {
    .vas-itm-card {
      margin-left: -15px;
      margin-top: -32px;
      .vas-itm-card__title {
        color: var(--color-foreground-primary);
        font-weight: @font-weight-regular;
      }
    }
    .vas-itm-card__image {
      width: 48px;
      height: 48px;
    }
  }

  .field-secondory-label {
    margin-left: 2.15rem;
    margin-bottom: @spacing-200;
  }

  .vault-interstitial-cta {
    margin-top: @spacing-100;
    float: right;
  }

  .spoke-body-primary {
    display: flex;
  }

  .vas-spoke-body-primary-left {
    flex: 1 1 auto;
  }

  .vas-interstitial-footer {
    width: 100%;
    box-sizing: border-box;
    margin-top: @spacing-300;
    margin-bottom: @spacing-200;

    &.vault-adoption-interstitial-footer {
      font-size: 0.75rem;
      padding-left: @spacing-200;
      padding-right: @spacing-200;
    }

    .ux-textspans--INLINE_LINK {
      color: #707070;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .high-asp {
    .section-notice {
      display: block;
      .section-notice__header {
        height: auto;
      }
    }
  }

  .vas-card {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: @spacing-200;
    margin-top: @spacing-200;

    &-icon {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }

    &-content {
      flex: 1;

      &-title {
        margin-bottom: 6px;
        color: var(--Light-Neutrals-Neutral-7, #111820);
        font-weight: 700;
      }

      &-description {
        font-size: 0.875rem;
        color: var(--foreground-secondary, var(--foreground-secondary, #707070));
        font-weight: 400;
      }
    }
  }
}
