@import './common.less';

.parts-scheduling {
  &__map-operation {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-background-tertiary);
  }
  &__content,
  &__provider,
  &__map-operation,
  &__service-operation {
    display: flex;
  }

  &__content {
    justify-content: space-between;
    padding-bottom: 80px;
  }

  &__left,
  &__right {
    width: 50%;
  }

  &__left {
    padding: 0 16px 32px 32px;
    //height: 705px;
    //overflow-y: scroll;
  }

  &__right {
    padding: 0 32px 32px 16px;
    //height: 705px;
    //overflow-y: scroll;
    .parts-scheduling__faq {
      margin-top: 24px;
      .details__summary {
        color: var(--color-foreground-primary);
        font-weight: bold;
        padding-left: 0;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &__image {
    height: 132px;
    margin-bottom: 24px;
  }

  &__map-lists {
    height: 188px;
    width: 188px;
    margin-right: 16px;
  }

  &__phone {
    padding: 0 8px 0;
    button {
      text-align: left;
    }
  }

  &__calendar {
    padding-top: 16px;
  }

  &__help {
    border-bottom: 1px solid var(--color-background-tertiary);
  }

  &__installer-card {
    border-bottom: 1px solid var(--color-background-tertiary);
    .installer-card-no-radio__feature {
      display: inline-block;
      .typography-small();
      span.ux-textspans--SECONDARY {
        display: inline-block;
        white-space: pre-line;
        width: 220px;
      }
    }
  }

  &__footer {
    box-shadow: 0 -4px 4px #0000000f;
    &.sticky_footer {
      border-radius: 0 0 10px 10px;
    }
  }
  .screen-loading {
    padding: 0 32px 32px;
    height: 750px;
    &__content-top {
      .skeleton__textbox {
        height: 192px;
      }
    }
    &__content-main {
      padding-top: 32px;
    }
    &__content-right {
      .skeleton__image {
        height: 150px;
        margin-bottom: 20px;
      }
    }
  }
}